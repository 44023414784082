import React, { useEffect, useRef, useState, createContext } from "react";
import { v4 as uuidv4 } from "uuid";

import socketIOClient from "socket.io-client";

import useSound from "use-sound";
import sendSfx from "../assets/sounds/boop.mp3";
import newMessageSfx from "../assets/sounds/pop-up-off.mp3";
import { getUserFullName, usePrevious, stingForFilter, isVisible } from "../Tools";
import Home from "./Home";

import "./ChatRoom.css";

var dateFormat = require("dateformat");

const NEW_CHAT_MESSAGE_EVENT = "newChatMessage";
const NEW_SYSTEM_MESSAGE_EVENT = "newSystemMessage";
const NEW_CONNECTIONS_MESSAGE_LIST_EVENT = "newConnectionsMessageList";
const NEW_USERS_MESSAGE_LIST = "newUsersMessageList";
const NEW_CHAT_MESSAGE_SEND_EVENT = "newChatMessageSend";
const NEW_USER_MESSAGE_HISTORY_EVENT = "newUsersMessageHistory";

const count = 50;

const noUserCommands = ["@@connections", "@@groups", "@@users", "@@παρουσίες", "@@παρουσιες"];

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const beta = false;
//const beta = window.location.href.includes("localhost") || window.location.href.includes("rccb33") || window.location.href.includes("rcck133") ? true : false;
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const SOCKET_SERVER_URL = beta ? "https://rccb33.rc.auth.gr:4000" : "https://messenger.rc.auth.gr:4000";

export const appContext = React.createContext();

const ChatRoom = (props) => {
  const ver = window.version;

  const roomId = "#";
  const urlId = props.match.params.urlId ?? localStorage.getItem("urlId") ?? undefined;

  //  console.log("ChatRoom", props.match.params.urlId, localStorage.getItem("urlId"), urlId);

  const winClientVersion = props.match.params.clientVersion;

  const [userId, setUserId] = useState();
  const [imageData, setImageData] = useState(null);
  const [userSettings, setUserSettings] = useState({});
  const [messages, setMessages] = useState(null);
  const [groups, setGroups] = useState([]);
  const [connections, setConnections] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersFilter, setUsersFilter] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [newMessageData, setNewMessageData] = useState({});
  const [newMessageSelectionStart, setNewMessageSelectionStart] = useState(0);
  const [newMessageSelectionEnd, setNewMessageSelectionEnd] = useState(0);
  const [settingsPanelVisible, setSettingsPanelVisible] = useState(0);
  const [searchPanelVisible, setSearchPanelVisible] = useState(0);
  const [personalGroupPanel, setPersonalGroupPanel] = useState(null);
  const [expandedUser, setExpandedUser] = useState();
  const [inputPanelHeight, setInputPanelHeight] = useState(104);
  const [autoLoad, setAutoLoad] = useState(0);
  const [mainTabs, setMainTabs] = useState([{ id: "0", name: `Όλα τα μηνύματα` }]);
  const [activeMainTab, setActiveMainTab] = useState("0");
  const [mobileView, setMobileView] = useState(false);
  const [usersTab, setUsersTab] = useState(1);
  const [showRecorder, setShowRecorder] = useState(false);
  //const [userIdleTime, setUserIdleTime] = useState(-1);
  //const [userIdleTimeFromBrowser, setUserIdleTimeFromBrowser] = useState(-1);

  const inputEl = useRef();
  const socketRef = useRef();

  window.userIdleTime = -1;
  window.setUserIdleTime = (e) => {
    window.userIdleTime = e;
  };
  window.userIdleTimeFromBrowser = -1;

  const sendResponse = (response) => {

    try {
      let tmp;

      if (typeof response === "string") tmp = JSON.parse(atob(response));
      else tmp = response;

      const user = tmp.user;
      const text = "@@response ~Command:\n " + tmp.exec + " \n\nResponse:\n" + tmp.output + (tmp.error ?? "");

      console.log("sendResponse", user, text);
      console.log("-----------------------------------------------------");

      const msg = {
        body: text,
        to: user,
        toId: tmp.senderId,
        from: userId,
        time: Date.now(),
        type: "response",
      };

      socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, msg);
    } catch (error) {
      console.log(error);
    }
  };

  window.sendResponse = sendResponse;

  const [noUserMessages, setNoUserMessages] = useState([]);

  const [conversations, setConversations] = useState(null);

  const [values, setValues] = useState({});

  // const [width, setWidth] = useState(window.innerWidth);
  // const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    // setWidth(window.innerWidth);
    // setHeight(window.innerHeight);
    // console.log(window.innerHeight);
  };

  useEffect(() => {
    if (beta && users.length > 0 && userId) {
      setMainTabs((prev) => {
        let tmp = [...prev];
        tmp[0].name = `Όλα τα μηνύματα (${users.find((u) => u.id == userId)?.displayName})`;
        return tmp;
      });
    }
  }, [userId, users]);

  useEffect(() => {
    if (!localStorage.getItem("isBeta")) {
      localStorage.setItem("isBeta", true);
    }

    if (activeMainTab !== "0" && !conversations) {
      getConversations();
    }

    if (messages) {
      loadMessages();
    }
    if (conversations && conversations.filter((c) => c.id === activeMainTab)[0] && conversations.filter((c) => c.id === activeMainTab)[0].unRead) {
      setConversations((prevConversations) => {
        let tmp = [...prevConversations];
        tmp.filter((c) => c.id === activeMainTab)[0].unRead = false;
        return tmp;
      });
    }
  }, [activeMainTab]);

  let lastActiveTime = Date.now();

  const handleUserActivity = () => {
    lastActiveTime = Date.now();
  };

  useEffect(() => {
    if (props.match.params.urlId && !localStorage.getItem("urlId")) {
      localStorage.setItem("urlId", props.match.params.urlId);
    }
    if (!localStorage.getItem("isBeta")) {
      localStorage.setItem("isBeta", true);
    }

    setInterval(() => {
      const idleTime = Date.now() - lastActiveTime;

      window.userIdleTimeFromBrowser = (idleTime / 1000).toFixed(0);
    }, 1000);

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, []);

  var loading = false;
  window.loading = loading;

  const [sendSound] = useSound(sendSfx, { volume: 0.05 });
  const [newMessageSound] = useSound(newMessageSfx, { volume: 0.05 });

  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    if (roomId && urlId) {
      socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
        query: {
          roomId,
          userId: urlId,
          winClientVersion,
          webclient: props.webclient,
          ver: ver,
        },
      });

      socketRef.current.on(NEW_USER_MESSAGE_HISTORY_EVENT, (message) => {
        //console.log(message.body)
        if (JSON.parse(message.body).messages) {
          try {
            setMessages((prevMessages) => {
              let newMessages = JSON.parse(message.body).messages;

              const packetData = JSON.parse(newMessages.filter((m) => m.id === "@@history")[0].data);

              if (packetData.hasMoreMessages !== "1") newMessages = newMessages.filter((m) => m.id !== "@@history");

              if (packetData.reset === 0) {
                var tmp = [...prevMessages.filter((m) => m.id !== "@@history"), ...newMessages];
                window.loading = false;
                return tmp;
              } else {
                window.loading = false;
                //window.scrollToBottom();
                return newMessages;
              }
            });
          } catch (error) {
            window.loading = false;
            console.log(error);
          }
        }
      });

      socketRef.current.on(NEW_CHAT_MESSAGE_EVENT, (message) => {
        // if (message.senderId !== socketRef.current.id) {
        //   console.log("new_newmessage", message);
        // }
        const incomingMessage = {
          ...message,
          sendByMe: false,
        };
        // console.log("@@new-message", incomingMessage);

        // if (in)
        // try {
        //   window.postMessageToApp({ newMessage: incomingMessage });
        // } catch {}

        window.newMessageArrived(incomingMessage);
      });

      socketRef.current.on(NEW_CHAT_MESSAGE_SEND_EVENT, (message) => {
        const outcoming = {
          ...message,
          sendByMe: true,
        };
        //        console.log("@@new-message-send", outcoming);

        try {
          window.postMessageToApp({ newMessage: outcoming });
        } catch { }
        window.scrollToBottom();
        //setMessages((prevMessages) => [outcoming, ...prevMessages]);
        window.newMessageArrived(outcoming);
      });

      socketRef.current.on(NEW_SYSTEM_MESSAGE_EVENT, (message) => {
        if (!message) return;

        if (message.body.startsWith("@@conversations ")) {
          const s = JSON.parse(message.body.substring(16));
          setConversations(s);
        } else if (message.body.toLowerCase().startsWith("@@refresreact") || message.body.toLowerCase().startsWith("@@refreshreact")) {
          if (props.winclient === "true") {
            console.log("@@reload-cefsharp");
            try {
              window.postMessageToApp({ refreshReact: true });
            } catch { }
          } else {
            window.location.reload();
          }
        } else if (message.body.toLowerCase().startsWith("@@restartwinclient")) {
          if (props.winclient === "true") {
            console.log("@@restart-WinClient");
            try {
              window.postMessageToApp({ restartWinClient: true });
            } catch { }
          }
        } else if (message.body.startsWith("@@userSettings ")) {
          const s = JSON.parse(message.body.substring(15));

          if (JSON.stringify(s) !== JSON.stringify(userSettings)) {
            setUserSettings((prevUserSettings) => {
              if (prevUserSettings.nameFirst !== s.nameFirst) {
                setUsersDisplayName(null, s.nameFirst);
              }

              return s;
            });
          }
        } else if (message.body.startsWith("@@addTagToMessage ")) {
          const id = message.body.split(" ")[1];
          const tag = message.body.split(" ")[2];
          setMessages((prevMessages) => {
            var tmpMessages = [...prevMessages];

            tmpMessages.filter((m) => m.id === id && !(m.tags + "#").includes(tag)).forEach((m) => (m.tags = m.tags + tag));

            return tmpMessages;
          });
        } else if (message.body.startsWith("@@removeTagFromMessage ")) {
          console.log("new mesage-> @@removeTagFromMessage", message.body);
          const id = message.body.split(" ")[1];
          const tag = message.body.split(" ")[2];
          setMessages((prevMessages) => {
            var tmpMessages = [...prevMessages];
            tmpMessages.filter((m) => m.id === id && (m.tags + "#").includes(tag)).forEach((m) => (m.tags = m.tags.replace(tag, "")));

            return tmpMessages;
          });
        } else {
          var data = JSON.parse(message.body);

          setUserId(data.userId);

          setUserSettings(data.settings);

          setUsersDisplayName(data.users, data.settings.nameFirst);

          setGroups(data.groups);

          setConversations(data.userConversations);

          setExpandedUser();

          try {
            const full_name_msg = data.users.filter((u) => u.id === data.userId)[0].lastName + " " + data.users.filter((u) => u.id === data.userId)[0].firstName;

            window.postMessageToApp({
              userFullName: full_name_msg,
            });
          } catch { }
        }
      });

      socketRef.current.on(NEW_CONNECTIONS_MESSAGE_LIST_EVENT, (message) => {
        var data = JSON.parse(message.body);
        setConnections(data);
      });

      socketRef.current.on(NEW_USERS_MESSAGE_LIST, (message) => {
        var data = JSON.parse(message.body);
        setUsers(data.users);
      });

      return () => {
        socketRef.current.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId, newMessageSound]);

  useEffect(() => {
    if (users && users.length > 0 && (!messages || messages.length === 0)) {
      loadMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    if (userSettings.zoom) {

      try {
        window.postMessageToApp({ zoom: userSettings.zoom });
      } catch { }
    }
    if (userSettings.dialer) {
      try {
        window.postMessageToApp({ dialer: userSettings.dialer });
      } catch { }
    }
  }, [userSettings]);

  useEffect(() => {
    window.loading = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  window.addFile = (id, name) => {
    console.log("Adding File->-", id, name);
    setNewMessageData((prevNewMessageData) => {
      var tmp = { ...prevNewMessageData };
      tmp.files.push({ id, name });
      return tmp;
    });
  };

  window.Search = (filter, conversation) => {
    loadMessagesFromSearch(filter, conversation);
  };

  const loadMessagesFromSearch = (filter, conversation) => {
    console.log("loadMessagesFromSearch", activeMainTab);
    if (activeMainTab) {
      const msg = {
        body: `@@getHistory reset:1, clientVersion:${ver}, conversation:${conversation.split(",").join("-")}, filter:${filter}`,
      };
      //console.log(msg.body);
      window.loading = true;
      socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, msg);
      console.log(msg.body);
    }
  };

  const getUsersFromConversation = (id) => {
    if (id.startsWith("##")) {
      return userSettings.groups[id.substring(2)].users.map((u) => u.id);
    } else if (id.startsWith("#")) {
      const tmp = groups.filter((g) => g.id.toString() === id.substring(1))[0];
      if (tmp) {
        return JSON.parse(tmp.users).map((u) => u.id);
      }
    }
    return [];
  };

  const loadMessages = () => {
    if (activeMainTab) {
      let conversation = activeMainTab.split(",").join("-");

      if (conversation.includes("#") && conversation != "#1") {
        let tmpUsets = [];
        conversation.split("-").forEach((s) => {
          if (s.startsWith("##")) {
            console.log(s, userSettings.groups[s.substring(2)].users);

            userSettings.groups[s.substring(2)].users.forEach((uu) => tmpUsets.push(uu));
          } else if (s.startsWith("#")) {
            const tmp = groups.find((g) => g.id.toString() === s.substring(1));
            console.log(tmp);
            if (tmp) {
              let gusers = JSON.parse(tmp.users).map((u) => u.id);
              console.log(gusers);
              gusers.forEach((u) => tmpUsets.push(u));
            }
          } else tmpUsets.push(s);
        });

        //short tmpUsets and remove duplicates
        conversation = tmpUsets
          .sort((a, b) => parseInt(a) - parseInt(b))
          .filter((v, i, a) => a.indexOf(v) === i)
          .join("-");
      }

      const mainTab = mainTabs.filter((t) => t.id === activeMainTab)[0];
      let group;
      if (mainTab) group = mainTab?.tabLabel?.split(",").join("-");

      const msg = {
        body: `@@getHistory force:${autoLoad}, reset:1, clientVersion:${ver}, count:${count}${activeMainTab === "0" ? "" : `, conversation:${conversation}`}${group ? `, group:${group}` : ""
          }`,
      };

      window.loading = true;
      socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, msg);
      //      console.log(msg.body);
    }
  };

  const loadMoreMessages = (force) => {
    // if (force === true) {
    //   setAutoLoad(1);
    // }

    if (!window.loading && (autoLoad === 1 || force)) {
      window.loading = true;
      var tmpMessages = messages.filter((m) => m.id !== "@@history" && !m.id.endsWith("*") && !(m.tags + "#").includes("#pin#"));
      var firstMessage = 0;
      if (tmpMessages.length > 0) firstMessage = tmpMessages[tmpMessages.length - 1].id;

      var tmp = `@@getHistory force:${force ? 1 : autoLoad}, reset:0, clientVersion:${ver}, count:${count}, firstMessage:${firstMessage}${activeMainTab === "0" ? "" : ` , conversation:${activeMainTab.split(",").join("-")}`
        }`;
      console.log(tmp);
      sendSystemMessage(tmp);
    }
  };

  const getConversations = () => {
    console.log("getConversations...");
    const msg = {
      body: `@@conversations`,
      data: "{}",
      senderId: socketRef.current.id,
      from: userId,
      time: Date.now(),
      to: userId,
    };
    socketRef.current.emit(NEW_SYSTEM_MESSAGE_EVENT, msg);
  };

  window.postMessageToApp = (msg) => {
    window.chrome.webview.postMessage(msg);
  };

  window.newMessageArrived = (incomingMessage) => {

    if (incomingMessage.users !== "-" && incomingMessage.to === "") return;

    if (!incomingMessage.sendByMe && incomingMessage.body.toLowerCase().trim().startsWith("@@useridletime")) {
      console.log(
        `------> ${new Date().toLocaleString("el-GR", { timeZone: "Europe/Athens" })} ${incomingMessage.body.trim()} από τον χρήστη ${getUserDisplayName(
          incomingMessage.from
        )} <------`
      );

      //στο tmpText κειμενο που θα αναγραφετέ πόση ώρα είναι αδρανής
      //αν τα δευτερολετα ειναι μεγαλύτρα των 60 τοτε θα εμφανιστεί δειχνει λεπτα και δευτολεπτα
      //αν τα λεπτα είναι περισοτερα απο 60 τοτε θα εμφανιστεί ωρες λεπτα και δευτολεπτα
      //αν οι ωρες ειναι περισοτερες απο 24 τοτε θα εμφανιστεί ημερες ωρες λεπτα και δευτολεπτα

      var tmpText = "";
      const idleTime = window.userIdleTime > -1 ? window.userIdleTime : window.userIdleTimeFromBrowser;
      const idleTimeFor = window.userIdleTime > -1 ? "Ο υπολογιστής" : "Τα μηνύματά";
      const idleTimeState = window.userIdleTime > -1 ? "ανενεργός" : "ανενεργά";

      const days = Math.floor(idleTime / 86400);
      const hours = Math.floor((idleTime % 86400) / 3600);
      const minutes = Math.floor(((idleTime % 86400) % 3600) / 60);
      const seconds = Math.floor(((idleTime % 86400) % 3600) % 60);

      if (days > 0) tmpText = tmpText + days + (days === 1 ? " μέρα " : " μέρες ");

      if (seconds === 0 && minutes === 0 && days > 0 && hours > 0) tmpText = tmpText + "και ";
      if (hours > 0) tmpText = tmpText + hours + (hours === 1 ? " ώρα " : " ώρες ");

      if (seconds === 0 && (days > 0 || hours > 0 || minutes > 0)) tmpText = tmpText + "και ";
      if (minutes > 0) tmpText = tmpText + minutes + (minutes === 1 ? " λεπτό " : " λεπτά ");

      if (seconds > 0 && seconds < idleTime) tmpText = tmpText + "και ";
      if (seconds > 0) tmpText = tmpText + seconds + (seconds === 1 ? " δευτερόλεπτο " : " δευτερόλεπτα ");

      const out = `${idleTimeFor} μου ${tmpText === "" ? "δεν " : ""}είναι ${idleTimeState} ${tmpText}`;
      console.log(`------> ${out}`);
      const msg = {
        body: `@@response ${out}`,
        senderId: socketRef.current.id,
        to: incomingMessage.from,
        toId: incomingMessage.senderId,
        from: incomingMessage.to,
        time: Date.now(),
        type: "response",
      };

      socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, msg);
      return;
    }

    if (!incomingMessage.sendByMe && incomingMessage.body.toLowerCase().trim().startsWith("@@exec")) {
      //console.log(incomingMessage);
      if (props.winclient === "true") {
        console.log(`------> ${new Date().toLocaleString("el-GR", { timeZone: "Europe/Athens" })} @@exec από τον χρήστη ${getUserDisplayName(incomingMessage.from)} <------`);
        console.log(`------> ${incomingMessage.body.substring(6).trim()}`);
        window.postMessageToApp({ user: incomingMessage.from, exec: incomingMessage.body.substring(6), senderId: incomingMessage.senderId });
      }
      return;
    }

    var unknownGroup = false;

    if (incomingMessage.users === "-") {
      setUsersTab(0);
      setNoUserMessages((prevNoUserMessage) => {
        return [...(prevNoUserMessage ?? []), incomingMessage];
      });
      return;
    }

    newMessageSound();
    try {
      if (props.winclient === "true") {
        window.postMessageToApp({ newMessage: incomingMessage });
      }
    } catch { }

    if (incomingMessage.users === "*") { incomingMessage.users = "#1"; }
    else {
      incomingMessage.to
        .split(",")
        .filter((u) => u.startsWith("#"))
        .forEach((u) => {
          if (groups.filter((g) => "#" + g.id.toString() === u).length === 0) unknownGroup = true;
        });
      if (unknownGroup) incomingMessage.to = incomingMessage.users;
    }



    if (incomingMessage.users === activeMainTab || activeMainTab === "0") {
      setMessages((prevMessages) => [incomingMessage, ...prevMessages]);
    } else {
      setUsersTab(0);
    }

    

    addMessageToConversations(incomingMessage);
  };

  const addMessageToConversations = (msg) => {

    if (msg.users === "-" || msg.to === "") return;
    if (!msg.users) msg.users = msg.to;

    setConversations((prevConversations) => {
      var temporaryConversations =[...prevConversations];

      var foundConversation = temporaryConversations.filter((c) => c.id === msg.users)[0];
      if (!foundConversation) {
        foundConversation = { id: msg.users, label: msg.users };
        temporaryConversations.push(foundConversation);
      }


      foundConversation.date = JSON.stringify(new Date(msg.time)).replaceAll('"', "");
      foundConversation.msg = msg.body
        .replaceAll('<span style="font-size:30px">', '<span style="font-size:17px">')
        .replaceAll('<span style="font-size:20px">', '<span style="font-size:17px">');

      foundConversation.from = msg.from;
      if (msg.users !== activeMainTab && activeMainTab !== "0") {
        foundConversation.unRead = true;

        const myDiv = document.getElementsByClassName("conversations-container")[0];

        if (myDiv && myDiv.firstElementChild) {
          myDiv.firstElementChild.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
      temporaryConversations = temporaryConversations.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });

      return temporaryConversations;
   
    });
  };

  const setUsersDisplayName = (usersData, nameFirst) => {
    setUsers((prevUsers) => {
      if (usersData === null) usersData = [...prevUsers];
      usersData.forEach((u) => {
        u.displayName = getUserFullName(u.id, null, [u], nameFirst);
        u.displayNameGen = getUserFullName(u.id, "gen", [u], nameFirst);
      });
      return usersData;
    });
  };

  const updateUserSettingsOnServer = (settings, updateLocalSettings) => {
    if (updateLocalSettings) setUserSettings(settings);
    const msg = {
      body: `@@setUserSettings ${JSON.stringify(settings)}`,
    };
    socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, msg);
  };

  const sendSystemMessage = (messageBody) => {
    const msg = {
      body: messageBody,
      // senderId: socketRef.current.id,
      // from: userId,
      // time: Date.now(),
    };
    socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, msg);
  };

  const sendMessage = async (messageData) => {
    //    console.log("new_newmessage");

    var messageBody = document.getElementById("message-textarea").value;

    console.log("messageBody", messageBody);
    console.log("messageBody.value", document.getElementById("message-textarea").value);

    await uploadAudioFirst(messageData);

    if (window.messageData)
      window.messageData.forEach((txt) => {
        if (txt.toLowerCase().startsWith("http")) {
          messageBody = messageBody.split(txt).join('<a href="' + txt + '" target="_blank">' + txt + "</a>");
        } else if (txt.toLowerCase().startsWith("www")) {
          messageBody = messageBody.split(txt).join('<a href="' + txt + '" target="_blank">' + txt + "</a>");
        } else if (txt.toLowerCase().startsWith("\\\\")) {
          messageBody = messageBody.split(txt).join('<a href="localfile:' + txt.split("\\").join("/") + '" target="_blank">' + txt + "</a>");
        } else if (txt.toLowerCase().startsWith("u:\\") || txt.toLowerCase().startsWith("y:\\")) {
          messageBody = messageBody.split(txt).join('<a href="localfile:' + txt.split("\\").join("/") + '" target="_blank">' + txt + "</a>");
        }
      });

    window.messageData = [];

    var to = [];
    if (messageData.noUserCommand !== true) {
      selectedUsers.filter((t) => !t.startsWith("##")).forEach((tt) => to.push(tt));

      selectedUsers
        .filter((t) => t.startsWith("##"))
        .forEach((tt) => {
          userSettings.groups[tt.substring(2)].users.forEach((ttt) => to.push(ttt));
        });

      to = [...new Set(to)].sort((a, b) => parseInt(a) - parseInt(b));
    }

    console.log("messageData", messageData);

    let tmp = { ...messageData };
    if (tmp.audio) {
      tmp.files = tmp.files ? [...tmp.files, tmp.audio] : [tmp.audio];
      delete tmp.audio;
    }
    console.log("messageData", tmp);
    const msg = {
      body: messageBody,
      data: JSON.stringify(tmp),
      senderId: socketRef.current.id,
      to: to.join(","),
      from: userId,
      time: Date.now(),
    };

    window.clearFiles();
    socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, msg);
  };

  const clear = () => {
    window.setAppValue("multiSelect", false);
    setAutoLoad(0);
    window.loading = true;
    setNewMessage("");
    setNewMessageData({});
    setMessages((prevMessages) => prevMessages.filter((m) => m.id === "@@history" || m.tags?.includes("#pin")));
    setSelectedUsers([]);
    setNoUserMessages([]);
    window.clearFiles();

    updateUserSettingsOnServer({
      ...userSettings,
      lastClear: dateFormat(Date.now(), "yyyy-mm-dd HH:MM:ss"),
    });

    setUserSettings({
      ...userSettings,
      lastClear: dateFormat(Date.now(), "yyyy-mm-dd HH:MM:ss"),
    });
  };

  const uploadAudioFirst = async () => {
    if (window.messageAudioBlob) {
      console.log("uploading audio file...");
      const formData = new FormData();
      const id = uuidv4();
      console.log(id);
      formData.append("auth", "jdtw4eutjaer95egje9458gbpweg936erigw49581");
      formData.append("id", id);
      formData.append("file", window.messageAudioBlob, "audio.webm");

      //formData.append("file", window.messageAudioBlob);

      console.log(formData);
      const response = await fetch(`${SOCKET_SERVER_URL}/files`, {
        method: "POST",
        body: formData,
      });

      console.log("Success:", response);
      window.messageSetAudioBlob(undefined);

      newMessageData.audio = { id: id, name: "audio.webm" };
    }
  };

  const handleSendMessage = (newMessageData, setNewMessageData) => {
    if (document.getElementById("message-textarea").value.toString().trim() === "@@admin") {
      window.admin = !window.admin;
      setNewMessage("");
      return;
    }

    if (document.getElementById("message-textarea").value.toString().trim() !== "" || window.messageAudioBlob || (newMessageData.files && newMessageData.files.length > 0)) {
      let noUserCommand = false;
      // check if massage start whith noUserCommands
      if (noUserCommands.includes(document.getElementById("message-textarea").value.toString().trim().split(" ")[0].toLowerCase())) {
        noUserCommand = true;
        setActiveMainTab("0");
        newMessageData.noUserCommand = true;
      }

      if (selectedUsers.length !== 0 || noUserCommand) {
        if (selectedUsers.includes("#1") && !noUserCommand) {
          //επιβεβαίωση αποστολής σε όλους τους χρήστες
          setModalData({
            title: "Προσοχή",
            msg: (
              <>
                Έχετε επιλέξει <span style={{ color: "#555", fontWeight: "600" }}>όλους τους χρήστες</span> για αποδέκτες του μηνύματος!
                <br />
                <br />
                Επιβεβαίωνετε την αποστολή;
              </>
            ),
            isQuestion: true,
            onOk: () => {
              sendMessage(newMessageData);
              setNewMessage("");
              setNewMessageData({});
              sendSound(1);
              window.setAppValue("multiSelect", false);
            },
          });
        } else {
          sendMessage(newMessageData);
          setNewMessage("");
          setNewMessageData({});
          sendSound(1);
          window.setAppValue("multiSelect", false);
        }
      } else {
        setModalData({
          title: "Πρόβλημα",
          msg: "Δεν έχει επιλεγεί αποδέκτης!",
        });
      }
    }
  };

  const userList = (content) => {
    let u = [];

    if (!content || content === "users")
      u = users
        .filter((u) => u.status == 1)
        .map((u) => {
          const data = JSON.parse(u.data);
          return {
            id: u.id,
            displayName: u.displayName,
            displayNameGen: u.displayNameGen,
            isGroup: false,
            isPublic: true,
            isBroadcast: false,
            filterData: stingForFilter(
              u.firstName + " " + u.lastName + " " + data.phone + " " + data.email + " " + data.client + " " + data.department + " " + u.userName + " " + data.notes
            ),
            data,
            status: u.status,
          };
        })
        .sort((a, b) => a.displayName.localeCompare(b.displayName));

    let g = [];
    if (!content || content === "groups")
      g = groups
        .map((g, index) => {
          const data = JSON.parse(g.data);
          const groupUsers = JSON.parse(g.users);
          return {
            id: "#" + g.id,
            displayName: g.name,
            displayNameGen: g.id == 1 ? "Όλους τους χρήστες" : g.name,
            isGroup: true,
            isPublic: g.isPublic,
            isBroadcast: g.isBroadcast,
            filterData: stingForFilter(g.name + " " + g.notes + " " + data.description),
            data,
            users: groupUsers,
            order: g.order,
          };
        })
        .sort((a, b) => a.order - b.order);

    let ug = [];

    if (!content || content === "groups")
      if (userSettings && userSettings.groups) {
        ug = userSettings.groups.map((g, index) => {
          return {
            id: "##" + index,
            displayName: g.name,
            displayNameGen: g.name,
            isGroup: true,
            isPublic: false,
            isBroadcast: false,
            filterData: stingForFilter(g.name),
            data: {},
            users: g.users.filter((ggu) => u.find((uuu) => uuu.id == ggu) || ggu.startsWith("#")),
          };
        });
      }

    return [...new Set([...g, ...ug, ...u])];
  };

  const setActiveMainTabFromUsers = (tabId, tabLabel, focus, formUserSelection) => {
    var mainTab = mainTabs.filter((t) => t.id === tabId);
    if (mainTab.length !== 0) {
      if (activeMainTab !== mainTab[0].id && focus !== false) {
        setActiveMainTab(mainTab[0].id);
      }
    } else {
      setMainTabs([...mainTabs.filter((t) => t.id === "0"), { id: tabId, name: getUserDisplayName(tabLabel, "", userId), tabLabel }]);
      if (tabId && (focus !== false || activeMainTab !== "0")) setActiveMainTab(tabId);
    }
    let tmpUsers;

    if (tabLabel === "#1" || tabId === "#1") {
      tmpUsers = ["#1"];
    } else {
      tmpUsers = tabLabel?.includes("#") ? tabLabel.split(",") : tabId?.split(",");
      if (!tmpUsers) tmpUsers = [];

      if (tmpUsers.length === 2) tmpUsers = tmpUsers.filter((u) => u !== userId.toString());
    }

    if (formUserSelection !== 1) setSelectedUsers(tmpUsers);
  };

  const setSelectedUsersEx = (uid) => {
    if (uid.length === 0) {
      setSelectedUsers([]);
      setMainTabs([...mainTabs.filter((t) => t.id == 0)]);
      setActiveMainTab("0");

      return;
    }

    var tempUid = uid;

    if (!window.getAppValue("multiSelect")) {
      const myDiv = document.getElementById(`user-list-item-${uid[0]}`);
      if (myDiv && !isVisible(myDiv, myDiv.parentElement.parentElement)) {
        myDiv.firstElementChild.scrollIntoView({
          behavior: "auto",
        });
      }
    }

    if (userSettings && userSettings.groups) {
      userSettings.groups.forEach((ug, index) => {
        var tmp = ug.users.map((ugu) => {
          return ugu.id;
        });
        if (tmp.join(",") === uid.join(",")) tempUid = ["##" + index.toString()];
      });
    }

    if (uid.length === 1 && uid[0] === "#1") {
      setSelectedUsers(["#1"]);
      setActiveMainTabFromUsers("#1", "Όλοι οι χρήστες", true, 1);
      return;
    }

    var uids = [];
    uid
      .join(",")
      .split(",")
      .forEach((id) => {
        if (id.startsWith("##")) {
          //get user from user group

          userSettings.groups[id.substring(2)].users.forEach((uu) => uids.push(uu));
        } else if (id.startsWith("#")) {
          (JSON.parse(groups.filter((g) => g.id.toString() === id.replace("#", ""))[0].users) ?? []).map((u) => u.id).forEach((uu) => uids.push(uu));
          //uids.push(groups.filter((g) => g.id.toString() === id.replace('#', ''))[0].users.map(u => u.id));
        } else {
          uids.push(id);
        }
      });

    if (uids.filter((u) => u === userId).length === 0) {
      if (uids.length > 1) {
        tempUid.push(userId);
        tempUid = [...tempUid.sort((a, b) => a - b)];
      }
      uids.push(userId);
    }

    var uniqueUids = [...new Set(uids)].sort((a, b) => a - b);

    setSelectedUsers(tempUid);
    setActiveMainTabFromUsers(uniqueUids.join(","), uid.sort().join(","), true, 1);
  };

  const getUserDisplayName = (uId, option, ex) => {
    try {
      if (uId) {
        if (!Array.isArray(uId) && uId.toString().split(",").length > 1) {
          uId = uId.toString().split(",");
        }

        if (Array.isArray(uId)) {
          uId = uId.filter((i) => i !== ex);
        }

        if (Array.isArray(uId) && uId.length > 1) {
          var tmpName = "";

          if (userSettings && userSettings.groups) {
            userSettings.groups.forEach((ug) => {
              if (Array.isArray(ug.users)) {
                var tmp1 = ug.users
                  .map((ugu) => {
                    return ugu.id ? ugu.id : ugu;
                  })
                  .sort()
                  .join(",");
                var tmp2 = uId.sort().join(",");
                if (tmp1 === tmp2) tmpName = ug.name;
              }
            });
          }
          if (tmpName === "")
            tmpName = `${uId

              .slice(0, 5)
              //.filter((u) => u !== userId)
              .map((i) => {
                // users.filter((u) => u.id === i)[0].lastName.substring(0, 1) +
                // "." +

                if (!i.startsWith("#")) {
                  let u = users.filter((u) => u.id === i)[0];
                  return u ? u.displayName.split(" ")[0].substring(0, uId.length < 4 ? 15 : 3) : i;
                } else if (!i.startsWith("##")) {
                  // eslint-disable-next-line eqeqeq
                  let g = groups.filter((g) => g.id == i.substring(1))[0];
                  return g ? g.name : i;
                } else {
                  let ug = userSettings.groups.filter(
                    // eslint-disable-next-line eqeqeq
                    (g, index) => index == i.substring(2)
                  )[0];
                  return ug ? ug.name : i;
                }
              })
              .join(", ")}${uId.length > 5 ? ` +${uId.length - 5}` : ""}`;
          return "Ομάδα (" + tmpName + ")";
        } else if (Array.isArray(uId)) uId = uId[0];

        // const u = userList().filter((u) => u.id.toString() === uId.toString())[0];

        if (uId.startsWith("##")) {
          return userSettings.groups[uId.substring(2)].name;
        } else if (uId.startsWith("#")) {
          if (uId === "#1" && option === "gen") return "όλους τους χρήστες";
          return groups.filter((g) => uId === "#" + g.id)[0].name;
        } else {
          const u = users.filter((u) => u.id.toString() === uId.toString())[0];

          try {
            if (option === "gen") return u.displayNameGen;
            else return u.displayName;
          } catch {
            return uId;
          }
        }
      }
    } catch (error) {
      return uId;
    }
  };

  const setNewMessage = (string) => {
    const inputEl = document.getElementById("message-textarea");
    inputEl.value = string;
  };

  const addTextToNewMessage = (text) => {
    if (!window.messageData) window.messageData = [];
    window.messageData.push(text);

    const inputEl = document.getElementById("message-textarea");
    var newMessage = inputEl.value;
    const selectionStart = inputEl.selectionStart;
    const selectionEnd = inputEl.selectionEnd;

    if (selectionStart === selectionEnd) {
      var tmp = newMessage.substring(0, selectionStart) + text + newMessage.substring(selectionStart);

      setNewMessage(tmp);
    } else {
      setNewMessage(newMessage.substring(0, selectionStart) + text + newMessage.substring(selectionEnd));
    }

    var sel = selectionStart + text.length;
    inputEl.focus();
    inputEl.setSelectionRange(sel, sel);
  };

  window.addTextToNewMessage = addTextToNewMessage;

  const getTitleFromselectedUsers = (usr, ex) => {
    if (usr === "#1" || selectedUsers.includes("#1")) return "Όλοι οι χρήστες";

    var out = "";
    let checkArray = [];

    if (usr && !Array.isArray(usr)) checkArray = usr.split(",");
    else if (usr && Array.isArray(usr)) checkArray = usr;
    else if (selectedUsers.length > 0) checkArray = selectedUsers;

    // if (usr) {
    //   if (usr.split(",").length > 1) {
    //     usr
    //       .split(",")
    //       //.filter((u) => u !== ex)
    //       .forEach((u) => {
    //         out += getUserDisplayName(u) + "\n";
    //       });
    //   } else if (usr.split(",").length === 1 && usr.split(",")[0].startsWith("#")) {
    //     JSON.parse(groups.filter((g) => g.id.toString() === usr.split(",")[0].toString().replace("#", ""))[0].users)
    //       .map((u) => {
    //         return {
    //           id: u.id,
    //           displayName: getUserDisplayName(u.id),
    //         };
    //       })
    //       .sort((a, b) => a.displayName.localeCompare(b.displayName))

    //       .forEach((uu) => {
    //         out += uu.displayName + "\n";
    //       });
    //   }
    // } else {
    if (checkArray.length > 1) {
      selectedUsers
        //.filter((u) => u !== ex)
        .forEach((u) => {
          out += getUserDisplayName(u) + "\n";
        });
    } else if (checkArray.length === 1 && checkArray[0].startsWith("##")) {
      userSettings.groups[checkArray[0].substring(2)].users
        .map((u) => {
          return {
            id: u.id ? u.id : u,
            displayName: getUserDisplayName(u.id ? u.id : u),
          };
        })
        .sort((a, b) => a.displayName.localeCompare(b.displayName))

        .forEach((uu) => {
          out += uu.displayName + "\n";
        });
    } else if (checkArray.length === 1 && checkArray[0].startsWith("#")) {
      JSON.parse(groups.filter((g) => g.id.toString() === checkArray[0].toString().replace("#", ""))[0].users)
        .map((u) => {
          return {
            id: u.id,
            displayName: getUserDisplayName(u.id),
          };
        })
        .sort((a, b) => a.displayName.localeCompare(b.displayName))

        .forEach((uu) => {
          out += uu.displayName + "\n";
        });
    }

    return out;
  };

  const getExpandedUser = () => {
    return expandedUser;
  };

  const appState = {
    inputEl,
    userId,
    modalData,
    setModalData,
    userSettings,
    setUserSettings,
    users: users,
    connections,
    selectedUsers,
    setSelectedUsers,
    messages,
    handleSendMessage,
    userList,
    groups,
    getUserDisplayName,
    setNewMessage,
    newMessageData,
    setNewMessageData,
    newMessageSelectionStart,
    setNewMessageSelectionStart,
    newMessageSelectionEnd,
    setNewMessageSelectionEnd,
    addTextToNewMessage,
    getTitleFromselectedUsers,
    usersFilter,
    setUsersFilter,
    imageData,
    setImageData,
    rootURL: SOCKET_SERVER_URL,
    clear,
    sendSystemMessage,
    webclient: props.webclient,
    winclient: props.winclient,
    settingsPanelVisible,
    setSettingsPanelVisible,
    updateUserSettingsOnServer,
    setInputPanelHeight,
    inputPanelHeight,
    searchPanelVisible,
    setSearchPanelVisible,
    personalGroupPanel,
    setPersonalGroupPanel,
    getExpandedUser,
    setExpandedUser,
    setUsersDisplayName,
    loadMoreMessages,
    autoLoad,
    usersTab,
    setUsersTab,
    mainTabs,
    setMainTabs,
    activeMainTab,
    setActiveMainTab,
    conversations,
    setConversations,
    getConversations,
    setActiveMainTabFromUsers,
    setSelectedUsersEx,
    mobileView,
    setMobileView,
    noUserMessages,
    setNoUserMessages,
    showRecorder,
    setShowRecorder,
  };

  ///////////////////////////
  window.getAppValue = (name) => {
    return values[name];
  };

  window.setAppValue = (name, value) => {
    setValues((prevValues) => {
      var tmp = { ...prevValues };
      tmp[name] = value;
      return tmp;
    });
  };

  window.getAppState = () => {
    return appState;
  };

  ///////////////////////////

  if (!userSettings || !userId) return <div />;
  else
    return (
      <appContext.Provider value={appState}>
        <Home appState={appState} />
      </appContext.Provider>
    );
};

export default ChatRoom;
